'use strict';

angular.module('kljDigitalLibraryApp')

.controller('MainCtrl', ["$rootScope", "$scope", "$state", "$stateParams", "commonVariables",
    "appConfig", "SubscriptionService", "OrderService", "ngToast", "$cookies", "MainService", "$location","AccountDetailsService", 
    "guestAccountService", "guestLoginService","Auth",
    function($rootScope, $scope, $state, $stateParams, commonVariables,
        appConfig, SubscriptionService, OrderService, ngToast, $cookies, MainService, $location, AccountDetailsService, 
        guestAccountService,guestLoginService, Auth) {

        var init = function() {
            $rootScope.title = 'Welcome';
            $rootScope.currentUrl = $state.current.name;
            getStateCityInfo();

            //Scroll top
            $(document).ready(function() {
                $(this).scrollTop(0);
            });

            if ($state.current.name === 'main') {
                if ($cookies.get('token')) {
                    $state.go('index.home');
                }
            }
            if ($state.current.name === 'main.subscriptions') {
                if ($cookies.get('token')) {
                    $state.go('index.home');
                } else {
                    getSubscriptions();
                }
            }
            if ($state.current.name === 'main.orderSummary') {
                $rootScope.title = 'Order Summary';
                if ($stateParams.ordernumber) {
                    getOrder($stateParams.ordernumber);
                }
                // }
            }
            if ($state.current.name === 'main.web_edition') {
                $rootScope.title = 'Web Edition';
                getSubscriptionsList({
                    criteria: "online"
                });
            }

            if ($state.current.name === 'main.access_code') {
                $rootScope.title = 'Accesscode Subscription';
                if ($cookies.get('token')) {
                    $state.go('index.home');
                } else {
                    getSubscriptions();
                }
                // getSubscriptionsList({
                //     criteria: "online"
                // });
            }

            if ($state.current.name === 'main.cd-rom_edition') {
                $rootScope.title = 'CD-ROM Edition';
                getSubscriptionsList({
                    criteria: "offline"
                });
            }
            if ($state.current.name === 'main.trial_version') {
                $rootScope.title = 'Trial Version';
                getSubscriptionsList({
                    criteria: "trial"
                });
            }
        }

        var getSubscriptions = function() {
            return SubscriptionService.availablePlans()
                .then(function(response) {
                    $scope.subscriptions = response;
                })
                .catch(function(error) {
                    console.log("error-->", error);
                });
        }

        //Get subscriptions
        var getSubscriptionsList = function(body) {
            return MainService.getSubscriptions(body)
                .then(function(response) {
                    for (var i = 0; i < response.length; i++) {
                        if (response[i].category === 'KLJ') {
                            $scope.klj_plan = response[i];
                        } else if (response[i].category === 'KLJ Tribunal') {
                            $scope.klj_tribunal_plan = response[i];
                        } else if (response[i].category === 'Combo') {
                            $scope.combo_plan = response[i];
                        }
                        else if(response[i].category==='KLJ Yearly'){
                                $scope.klj_yearly_plan = response[i];
                        }
                         else if(response[i].category==='KLJ Tribunal Yearly'){
                                $scope.klj_tribunal_yearly_plan = response[i];
                        }
                    }
                    // $scope.klj_plan = response[0];
                    // $scope.klj_tribunal_plan = response[1];
                    // $scope.combo_plan = 
                })
                .catch(function(error) {
                    console.log("error-->", error);
                });
        }

        //Get order details by order number
        var getOrder = function(id) {
            SubscriptionService.getdetailsUsingOrder(id)

            .then(function(data) {

                $scope.details = data;
                if (data && $scope.details && $scope.details.order && $scope.details.order.coupons && $scope.details.order.coupons[0] === null) {
                    $scope.details.order.coupons = [];
                }
            })

            .catch(function(error) {
                ngToast.danger("Failed to load order details");
            })
        }

        $scope.goToContact = function() {
            $state.go('login', {
                contact_us: true
            });
        }

        $scope.redirectToHome = function() {
            $state.go('login', {
                contact_us: undefined
            });
            //Scroll top
            $(document).ready(function() {
                $(this).scrollTop(0);
            });
        }


        $scope.goToCombo = function() {
            //Scroll top
            $(document).ready(function() {
                $(this).scrollTop(2000);
            });
        }
            $scope.playVideo = function(){
            $scope.show='Show';
            $scope.stop='';

        }
        $scope.stopVideo = function(){
             $scope.stop='Stop';
             $scope.show='';
        }

        var getSubscriptions = function() {
            return SubscriptionService.availablePlans()
                .then(function(response) {
                    $scope.subscriptions = response;
                })
                .catch(function(error) {
                    console.log("error-->", error);
                });
        }

        var getSubscription = function() {
            
            return SubscriptionService.getSubscriptionDetails($stateParams.id)
                .then(function(data) {
                    $scope.planDetails = data;
                    if (data.price) {
                        $scope.order = {
                            subTotal: data.price.after_discount,
                            tax: ((data.price.after_discount * data.tax) / 100)
                        };

                        // $scope.order.total = $scope.order.subTotal + $scope.order.tax;
                    }
                    $scope.showAccesscodeInput=false;
                    $scope.confirmationBtn = true;
                })

            .catch(function(error) {

            });
        }

        var getStateCityInfo = function() {
            return MainService.getList()
                .then(function(response) {
                    $scope.citiesList = response.cities;
                    $scope.statesList = response.states;
                })
                .catch(function(error) {
                    console.log("error-->", error);
                });
        }

        // var getPrice = function(body) {
        //     var token = guestLoginService.getToken();
        //     return guestUserService.getPrice(body, token);
        // }

        var get_accesscode_order_intent_details = function(body) {
            
            var token = guestLoginService.getToken();            
            if(!token){
                $state.go('login')
                return;
            }
            return guestUserService.get_order_intent(body, token);
        }


        $scope.submitForm = function(valid, details) {
         
            $scope.submit = true;
            if (valid) {
                AccountDetailsService.create({
                        user: details
                    })
                    .then(function(response) {
                        $scope.mobile_number = response.data.mobile_number;
                        $scope.showOTP = true;
                    })
                    .catch(function(error) {
                        console.log(error);
                        ngToast.danger('Unable to save details');
                    });
            }
        }


        var getPriceApiBody = function(code) {

            var body = {};
            body.subscriptionId = $stateParams.id;
            body.code = code;
            return body;
        }

        var getAccesscodeBody = function() {

            var body = {};
            // body.subscriptionId = $stateParams.id;
            body.accesscodeSub = true;
            return body;
        }

        $scope.registeredUser = function(mobile_number) {
            $scope.submitted = true;
            if (mobile_number) {

                Auth.registerdUser(mobile_number)

                .then(function(data) {

                    $scope.showOTP = true;
                    $scope.isregisteredUser = true;
                    $scope.generateOTP(mobile_number);
                })

                .catch(function(error) {
                    $scope.isregisteredUser = false;
                });
            } else {
                $scope.showOTP = false;
            }
        }

        $scope.generateOTP = function(mobile_number) {

            if (mobile_number) {

                Auth.generateOTP(mobile_number, $scope.googleToken)

                .then(function(success) {
                    // grecaptcha.reset();
                    $scope.showOTP = true;
                    $scope.optSentMessageStatus = true;
                    $scope.optSentMessage = "Password sent on mobile number "+mobile_number;
                })

                .catch(function(error) {
                    // grecaptcha.reset();
                    console.log(error);
                });
            }
        }

        $scope.verifyOTP = function(mobile_number, otp) {
            $scope.show = true;
            if (mobile_number && otp) {
                var body = {
                    user: {
                        mobile_number: mobile_number,
                        otp: otp
                    }
                };
                guestAccountService.verifyUser(body)
                    .then(function(response) {
                        guestLoginService.addToken(response.data.token);
                        $scope.success = true;
                        // $scope.showAccesscodeInput = true;
                        
                        $state.go('main.accesscode-plan-details', {

                        })
                       
                        })

                .catch(function(error) {
                    console.log("error>>>>>>>>>>>>> ",error);
                    if (error.data == 'Unauthorized') {
                        //redirect to home 
                        $scope.unAuth = true;
                    } else {
                        $scope.wrongOtp = true;
                    }
                })
            }

        }

        init();

    }
]);

function stopvideo() {
  
  var vid = document.getElementById("mykljvideo");
  vid.pause(); 
}

function playvideo() {
 
  var vid = document.getElementById("mykljvideo");
  vid.play(); 
}
